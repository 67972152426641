@import "./_fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "../images/map-icon.png"; */
/* global  */
body {
    @apply font-graphik;
}

h1 {
    @apply font-graphik text-3xl font-bold;
}

/* list  */
.list-inline li {
    @apply inline-block;
}

.list-block li {
    @apply block;
}

/* containers  */
.map-container {
    height: calc(100vh - 300px);
    @apply container relative m-auto z-10;
}

.mapboxgl-popup-content {
    @apply max-w-[160px] md:max-w-none h-[250px] overflow-y-scroll;
}

/* customized  */
.logo {
    @apply absolute left-4 lg:left-0 top-0 h-20 w-20;
}

/*  Menu */
.primary-menu > ul > li {
    @apply inline-block ml-14 font-wwf text-lg;
}

.primary-menu > ul > li > a {
    @apply flex items-center font-wwf text-lg;
    letter-spacing: 0.2px;
}

.primary-menu ul li a.btn {
    @apply font-sans text-xs max-w-fit lg:max-w-none;
}

.primary-menu .nav-list {
    @apply hidden opacity-0 lg:block lg:opacity-100 transition-all overflow-visible;
}

.primary-menu {
    transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    @apply fixed lg:static top-4 right-4 w-1 lg:w-auto h-1 lg:h-auto;
}

.primary-menu.expanded {
    @apply bg-black lg:bg-inherit top-0 right-0 h-full w-full overflow-y-scroll;
}

.primary-menu.expanded .nav-list {
    @apply block opacity-100 place-items-center justify-self-center mt-16;
}

.primary-menu.expanded .nav-list > li {
    @apply block mb-6;
}

.primary-menu.expanded .nav-list > li > a {
    @apply text-3xl;
}

.primary-menu.expanded .nav-list > li:last-of-type > a {
    @apply text-xl;
}

/* submenu  */
.primary-sub-menu {
    @apply bg-transparent p-0 m-0 z-50 justify-start min-h-fit pl-5 w-80 left-0 mt-2 rounded-md static -translate-y-0 transition-all origin-top scale-100 opacity-100
		lg:bg-white 
		lg:min-w-[195px]
		lg:absolute 
    lg:pl-0
		/* group-hover:opacity-100 group-hover:-translate-y-0 group-hover:scale-100; */;
}

.primary-sub-menu::before {
    content: "";
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #fff;
    @apply absolute top-[-5px] left-[42%] text-white border-b-gray-500;
}

.primary-sub-menu li {
    @apply overflow-hidden mb-0 first-of-type:rounded-tl-md first-of-type:rounded-tr-md last-of-type:rounded-bl-md last-of-type:rounded-br-md
	lg:border-b
	lg:border-gray-300;
}

.primary-sub-menu li a {
    @apply text-white block px-4 py-1 text-center text-3xl mt-1 mb-1
	lg:text-lg
	lg:text-gray-500
	lg:opacity-95
	lg:hover:bg-gray-100;
}

/* Page Realted */

.news-image,
.event-image {
    @apply rounded-md md:rounded-tr-none md:rounded-br-none;
}

.banner-slider {
    height: calc(100vh - 80px);
    overflow: hidden;
}

.slick-arrows {
    @apply absolute bottom-44 bg-gray-400 text-white z-50 rounded-full border border-white py-1 px-2 transition-all hover:bg-gray-500 hover:cursor-pointer hidden lg:block;
}

.slick-dots {
    @apply absolute bottom-5;
}

.slick-dots li button:before {
    @apply text-sm text-gray-700 opacity-100;
}

.slick-dots li.slick-active button:before {
    @apply text-white;
}

.mapboxgl-popup-close-button {
    @apply absolute right-4 top-3 border-0 text-2xl hover:cursor-pointer p-1 inline-block
        focus:outline-none focus:border-0;
}

.mapboxgl-popup {
    max-width: 320px !important;
}

.marker-image {
    @apply hover:cursor-pointer;
}

.mapboxgl-ctrl-bottom-left {
    display: none !important;
}

/* Map Stat counter */
.map-stat-title {
    @apply text-base font-graphik font-medium;
    line-height: 1.3rem;
}

.map-stat-counter {
    @apply font-wwf text-red-500 text-3xl mt-1 mb-2;
}

/* theme shadow  */
.banner-title {
    text-shadow: 2px 2px #000;
}

/* Page  */
.page-heading {
    @apply h-[85vh] md:h-[65vh] overflow-hidden;
}

/* climate smart entrepreneurship  */
.idea-wrapper {
    @apply flex flex-col bg-gray-200 pb-3 border-b-4 transition-all hover:shadow-lg;
}

.idea-detail-tab{
    @apply flex justify-start pt-4 pb-4  max-w-full overflow-x-scroll;
}

.idea-detail-tab .tab{
    @apply font-graphik_regular my-2 cursor-pointer border-b-2 border-transparent;
}
.idea-detail-tab .tab.active{
    @apply font-bold border-red-500;
}

/* Pagination  */
.pagination-container {
    @apply flex justify-center items-center mt-20;
}

.pagination-link a {
    @apply mx-2 rounded-full h-10 w-10 flex justify-center items-center hover:bg-gray-200 transition-all;
}

.pagination-link.pagination-active a {
    @apply bg-red-500 text-white;
}

.pagination-next {
    @apply mx-4 rounded-full h-10 w-10 flex justify-center items-center hover:bg-gray-200;
}

/* map filter - idea repository */
.map-filter-dark select,
.map-filter-dark button {
    @apply flex items-center p-0 bg-darkBlack text-white opacity-100 focus:outline-none text-xs px-2 w-full md:w-auto py-3 rounded-md mr-4;
}

/* Form  */
.th-input {
    @apply bg-gray-200 border px-4 py-3 rounded-md border-gray-300 font-graphik_regular text-base mt-1 placeholder:text-gray-500 focus:outline-none;
}

.th-input.error {
    @apply border-red-error;
}
select.th-input {
    @apply py-[15px];
}

select option:first-of-type {
    /* display: none; */
    color: red;
}

/* Overview Table  */
.overview-table {
    @apply text-sm text-black opacity-90 mt-2;
}
.overview-table tr td {
    @apply px-4 py-2 border border-gray-400;
}

.event-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.event-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.news-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.fellowshipActive {
    @apply border-b-4 border-green-500 text-green-500;
}

.teamBoardSelect {
    @apply border-b-4 border-red-500;
}
.faqSectionChild {
    @apply text-lg !important;
}
.faqSectionChild ul {
    @apply list-disc pl-6;
}
.faqSectionChild table {
    @apply mt-6;
}
.faqSectionChild tr {
    @apply bg-gray-200;
}
.faqSectionChild tr td {
    @apply px-4 py-2 font-graphik font-bold text-darkBlack;
}
.faqSectionChild tr:nth-child(2n) {
    @apply bg-gray-300;
}
.cse-about p {
    @apply font-graphik_regular mb-5;
}
.slick-dots li button::before {
    font-size: 16px;
    color: #e1e1e1;
    opacity: 0.7;
}

.slick-dots li.slick-active button::before {
    color: #8f8d8d;
    opacity: 1;
}

.marker {
    background-image: url("../images/map-icon.png");
    background-size: cover;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.highlight-page p {
    color: #212121;
    @apply my-4;
}
.highlight-page a {
    @apply underline text-link_color;
}
.highlight-page ul {
    @apply list-disc ml-6;
}
.highlight-page img {
    height: unset !important;
}
.active_header_element {
    @apply relative;
}

.active_header_element:before {
    @apply border-t-4 border-red-500;
    content: "";
    position: absolute;
    top: -21px;
    height: 5px;
    width: 100%;
}

.active_header_element:after {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #bc014e;
}

@media screen and (max-width: 1024px) {
    .active_header_element {
        display: none;
    }
    .active_header_element:before {
        display: none;
    }
    .active_header_element:after {
        display: none;
    }
}
.tooltip-info p {
    @apply text-gray-400;
}

.blog_filter_active {
    color: #0070ab;
    border-color: #0070ab;
    background-color: #d8f2ff;
}

.blog_filter {
    color: #405467;
    opacity: 0.7;
    border-color: #405467;
    background-color: transparent;
}


/*------------------------------------
  Rating v1
------------------------------------*/
.u-rating-v1 {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .u-rating-v1 > * {
    float: left;
    list-style: none;
    cursor: pointer;
    -webkit-transition-property: color;
    -o-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .2s;
         -o-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-timing-function: ease;
         -o-transition-timing-function: ease;
            transition-timing-function: ease;
  }
  
  .u-rating-v1 > * + * {
    padding-left: 6px;
  }

  .readmore-text > p:last-of-type {
    display: inline;
  }
  
  .read-or-hide {
    cursor: pointer;
  }