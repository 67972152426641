/* font-family : WWF  */
@font-face {font-family: "WWF Regular";
	src: url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.eot"); /* IE9*/
	src: url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.woff2") format("woff2"), /* chrome、firefox */
	url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.woff") format("woff"), /* chrome、firefox */
	url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
	url("../fonts/wwf/596b9b0742c4fb004a2adcaf9861a128.svg#WWF Regular") format("svg"); /* iOS 4.1- */
}

/* font-family : Graphik  */
@font-face {
    font-family: 'Graphik Bold';
    src: url('../fonts/graphik/Graphik-Bold.eot');
    src: url('../fonts/graphik/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/graphik/Graphik-Light.eot');
    src: url('../fonts/graphik/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Light.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/graphik/Graphik-Medium.eot');
    src: url('../fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Medium.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Regular';
    src: url('../fonts/graphik/Graphik-Regular.eot');
    src: url('../fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/graphik/Graphik-Semibold.eot');
    src: url('../fonts/graphik/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Semibold.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}